import React, {useEffect, useState} from "react"
import Table from '../../components/Table'

import jwt_decode from 'jwt-decode';
import axios from "axios"
import swal from 'sweetalert';

const server_url = process.env.REACT_APP_SERVER_URL;
const user_info = localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')) : null;

const tableHeader = ['Email', 'Raffle ID', 'Payment Type', 'Time'];

const getTimeString = (time) => {
  let temp = time.slice(0, time.lastIndexOf(":"));
  temp = temp.replace('T', ' ');
  return temp;
}

const renderHead = (item, index) => {
  return <th key={index}>{item}</th>
}

const renderBody = (item, index) => {
  return (
    <tr key={index}>
      <td>{item.player_email}</td>
      <td>{item.raffle_id}</td>
      <td>{item.type}</td>
      <td>{getTimeString(item.createdAt)}</td>
    </tr>
  )
}
export default function AllPayments(props) {

  const {username} = props;

  const [payments, setPayments] = useState([]);

  useEffect(() => {        
    updatePayments();
  }, [username]);

  const updatePayments = () => {
    axios.post(`${server_url}/getAllPayments`, {
      name: user_info.username,
      password: user_info.password,
      username : username
    }).then(res => {        
        if(!res.data.success) {
          swal("Error", "Error occured unexpectedly. Please refresh the page again", "error");
          return;
        }
        else {
          setPayments(res.data.payments);
        }
    }).catch(err => {
        console.log(err)
    })
  }

  return (
    <>
      <Table
        headData={tableHeader}
        bodyData={payments}
        renderHead={renderHead}
        renderBody={renderBody}
      />
    </>
  )
}
