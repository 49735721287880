import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Dashboard from './pages/Dashboard'
import Users from './pages/Users'
import Orders from './pages/Orders'
import Analytics from './pages/Analytics'
import Login from './pages/Login'
import Raffles from './pages/Raffles'
import RequireAdmin from './components/auth/require_admin';

export default function Routes() {
  return (
    <Switch>
      <Route exact path='/dashboard' component={RequireAdmin(Dashboard)} />
      <Route exact path='/users' component={RequireAdmin(Users)} />
      <Route exact path='/payments' component={RequireAdmin(Orders)} />
      <Route exact path='/analytics' component={RequireAdmin(Analytics)} />
      <Route exact path='/raffles' component={RequireAdmin(Raffles)} />
      <Route exact path='/' component={Login} />
    </Switch>
  )
}
