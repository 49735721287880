import React, {useEffect, useState} from "react"

import { Link } from 'react-router-dom'
import PaymentsTable from './PaymentsTable'
import LastOrdersTable from './LastOrdersTable'

import jwt_decode from 'jwt-decode';
import axios from "axios"
import swal from 'sweetalert';

const server_url = process.env.REACT_APP_SERVER_URL;
const user_info = localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')) : null

export default function Payments() {

  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState('');

  useEffect(() => {        
    updatePlayers();
  }, []);

  const updatePlayers = () => {
    axios.post(`${server_url}/getAllUsers`, {
      name: user_info.username,
      password: user_info.password
    }).then(res => {        
        if(!res.data.success) {
          swal("Error", "Error occured unexpectedly. Please refresh the page again", "error");
          return;
        }
        else {
          setPlayers(res.data.players);
        }
    }).catch(err => {
        console.log(err)
    })
  }

  const handlePlayerChange = (e) => {        
    setPlayer(e.target.value);
  }
  return (
    <div>
      <h2 className='page-header'>Payments</h2>

      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header'>
              <span className='select-text'>Player: </span>
              <select style={{ marginLeft:"30px" }} onChange={handlePlayerChange}>
                <option value=""> All </option>
                {players && players.length
                  ? players.map((item) => {
                      return (
                          <option key={item.id} value={item.username}> {item.username} </option>
                      );
                  }) : null
                }
              </select>
            </div>
            <div className='card-body'>
              <PaymentsTable 
                username={player}
              />
            </div>           
          </div>
        </div>
      </div>
    </div>
  )
}
