import React, {useEffect, useState} from "react"
import dashboardCards from '../../assets/jsonData/dashboardCards.json'
import Card from '../../components/Card'
import Chart from './Chart'
import LatestPlayers from './LatestPlayers'
import RevenueHistoryTable from './RevenueHistoryTable'
import jwt_decode from 'jwt-decode';
import axios from "axios"
import swal from 'sweetalert';

const server_url = process.env.REACT_APP_SERVER_URL;
const user_info = localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')) : null;

export default function Dashboard() {

  const [payments, setPayments] = useState(0);
  const [players, setPlayers] = useState(0);
  const [raffles, setRaffles] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [latestPlayers, setLatestPlayers] = useState([]);

  useEffect(() => {        
    updateDashboard();
  }, []);

  const updateDashboard = () => {
    axios.post(`${server_url}/getDashboardInfo`, {
      name: user_info.username,
      password: user_info.password
    }).then(res => {
        if(!res.data.success) {
          swal("Error", "Error occured unexpectedly. Please refresh the page again", "error");
          return;
        }
        else {
          setPayments(res.data.payments);
          setPlayers(res.data.players);
          setRaffles(res.data.raffles);
          setRevenue(res.data.total_revenue);
          setLatestPlayers(res.data.latest_players);
        }
    }).catch(err => {
        console.log(err)
    })
  }

  return (
    <div>
      <h2 className='page-header'>Dashboard</h2>
      <div className='row'>
        <div className='col-12'>
          <div className='row'>
            <div className='col-6'>
              <Card
                icon="bx bx-dollar"
                count={'$ ' + revenue}
                title="Total Revenue"
                color="blue"
              />
            </div>
            <div className='col-6'>
              <Card
                icon="bx bxs-cart"
                count={payments}
                title="Total Payments"
                color="amber"
              />
            </div>
            <div className='col-6'>
              <Card
                icon="bx bxs-user"
                count={players}
                title="Total Customers"
                color="green"
              />
            </div>
            <div className='col-6'>
              <Card
                icon="bx bxs-comment-detail"
                count={raffles}
                title="Total Completed Raffles"
                color="red"
              />
            </div>
          </div>
          <div className='row'>
            <div className='card'>
              <div className='card-header'>
                <h3>Recent 5 Users</h3>
              </div>
              <div className='card-body'>
                <LatestPlayers 
                  players={latestPlayers}
                />
              </div>
            </div>
          </div>
        </div>        
      </div>

      <div className='row'>
        <div className='col-6'>
          
        </div>
        <div className='col-6'>
          
        </div>
      </div>
    </div>
  )
}
