import React from 'react'
import Table from '../../components/Table'
import image from '../../assets/images/user-1.png'

const tableHeader = ['Image', 'Email', 'Winning Times', 'Paticipated Raffles', 'Member Since'];

const renderHead = (item, index) => {
  return <th key={index}>{item}</th>
}

const getTimeString = (time) => {
  let temp = time.slice(0, time.lastIndexOf(":"));
  temp = temp.replace('T', ' ');
  return temp;
}

const renderBody = (item, index) => {
  return (
    <tr key={index}>
      <td className='item-profile-holder'>
        <img
          className='item-profile-image'
          src={image}
          alt="User Profile"
        />        
      </td>
      <td>{item.username}</td>
      <td>{item.winnings}</td>
      <td>{item.participated_raffles}</td>
      <td>
        {getTimeString(item.createdAt)}
      </td>
    </tr>
  )
}
export default function LatestPlayers(props) {
  const { players } = props;

  return (
    <>
      <Table
        headData={tableHeader}
        bodyData={players}
        renderHead={renderHead}
        renderBody={renderBody}
      />
    </>
  )
}
