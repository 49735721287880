import React, {useEffect, useState} from "react"
import image from '../../assets/images/user-1.png'
import usersData from '../../assets/jsonData/users.json'
import Table from '../../components/Table'
import jwt_decode from 'jwt-decode';
import axios from "axios"
import swal from 'sweetalert';

const server_url = process.env.REACT_APP_SERVER_URL;
const user_info = localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')) : null;

const tableHeader =  [
  'Image', 'Email', 'Country', 'Birthday', 'Winning Times', 'Paticipated Raffles', 'Member Since'
];

const renderHead = (item, index) => {
  return <th key={index}>{item}</th>
}

const getTimeString = (time) => {
  let temp = time.slice(0, time.lastIndexOf(":"));
  temp = temp.replace('T', ' ');
  return temp;
}

const renderBody = (item, index) => {
  return (
    <tr key={index}>
      <img
          className='item-profile-image'
          src={image}
          alt="User Profile"
        />       
      <td>{item.username}</td>
      <td>{item.country}</td>
      <td>{item.birthday}</td>
      <td>{item.winnings}</td>
      <td>{item.participated_raffles}</td>
      <td>
        {getTimeString(item.createdAt)}
      </td>
    </tr>
  )
}
export default function UsersTable() {

  const [players, setPlayers] = useState([]);

  useEffect(() => {        
    updatePlayers();
  }, []);

  const updatePlayers = () => {
    axios.post(`${server_url}/getAllUsers`, {
      name: user_info.username,
      password: user_info.password
    }).then(res => {        
        if(!res.data.success) {
          swal("Error", "Error occured unexpectedly. Please refresh the page again", "error");
          return;
        }
        else {
          setPlayers(res.data.players);
        }
    }).catch(err => {
        console.log(err)
    })
  }

  return (
    <>
      <Table
        limit='10'
        headData={tableHeader}
        bodyData={players}
        renderHead={renderHead}
        renderBody={renderBody}
      />
    </>
  )
}
