import React, { useState, useEffect, useRef } from "react"

import RaffleHistoryTable from './RaffleHistoryTable'
import RafflePaticipantsTable from './RafflePaticipantsTable'
import socketIOClient from "socket.io-client";
import temp_product_image from '../../assets/images/tt.png'
import jwt_decode from 'jwt-decode';
import axios from "axios"
import swal from 'sweetalert';

const user_info = localStorage.getItem('token') ? jwt_decode(localStorage.getItem('token')) : null;
const socket_url = process.env.REACT_APP_SOCKET_URL;
const server_url = process.env.REACT_APP_SERVER_URL;


export default function Raffles() {

  const [timer1, setTimer1] = useState(28800);
  const [timer2, setTimer2] = useState(28800);
  const [timer3, setTimer3] = useState(28800);
  
  const [raffle1, setRaffle1] = useState(0);
  const [raffle2, setRaffle2] = useState(0);
  const [raffle3, setRaffle3] = useState(0);  

  const [preview1, setPreview1] = useState('');
  const [preview2, setPreview2] = useState('');
  const [preview3, setPreview3] = useState('');

  const [imageLoaded, setImageLoaded] = useState(false);

  const [raffleIDs, setRaffleIDs] = useState([]);

  const [selectedRaffle, setSelectedRaffle] = useState('');

  const fileInputRef1 = useRef(null);    
  const fileInputRef2 = useRef(null);    
  const fileInputRef3 = useRef(null);

  let raffle1_id = 0, raffle2_id = 0, raffle3_id = 0;

  useEffect(() => {
    const socket = socketIOClient(socket_url);
    socket.on("broadcast_time", data => {
      runTimer(data);
    });

    setTimeout(() => {
      loadPreviewImages();
    }, 1500);
    
  }, []);

  const runTimer = (data) => {
    setTimer1(data.timer.first);
    setTimer2(data.timer.second);
    setTimer3(data.timer.third);

    setRaffle1(data.raffle.first);
    setRaffle2(data.raffle.second);
    setRaffle3(data.raffle.third);

    raffle1_id = data.raffle.first;
    raffle2_id = data.raffle.second;
    raffle3_id = data.raffle.third;
  }

  const loadPreviewImages = () => {

    let raffles = [];
    for(let i=1; i<=raffle3_id; i++) {
      raffles.push(i);
    }

    setRaffleIDs(raffles);

    axios.post(`${server_url}/getProductImages`, {
      raffle1: raffle1_id,
      raffle2: raffle2_id,
      raffle3: raffle3_id
    }).then(res => {  
        if(res.data.raffle1_image) {
          setPreview1(`${server_url}/products/${res.data.raffle1_image}`);
        }
        if(res.data.raffle2_image) {
          setPreview2(`${server_url}/products/${res.data.raffle2_image}`);
        }
        if(res.data.raffle3_image) {
          setPreview3(`${server_url}/products/${res.data.raffle3_image}`);
        }
    }).catch(err => {
        console.log(err)
    })
  }

  const changeTime = (ms) => {
    var seconds = ms;
    var hoursLeft = Math.floor(seconds / 3600);
    var min = Math.floor((seconds - hoursLeft * 3600) / 60);
    var secondsLeft = seconds - hoursLeft * 3600 - min * 60;
    secondsLeft = Math.round(secondsLeft * 100) / 100;
    var answer = hoursLeft < 10 ? "0" + hoursLeft : hoursLeft;
    answer += ":" + (min < 10 ? "0" + min : min);
    answer += ":" + (secondsLeft < 10 ? "0" + secondsLeft : secondsLeft);
    return answer;
  }

  const handleSubmitImage = async (raffle_id, file) => {
    const fmData = new FormData();
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        "content-type": "multipart/form-data"
      },
      mode:'no-cors'
    };

    fmData.append("raffle_id", raffle_id);    
    fmData.append("file", file);

    try {
      const res = await axios.post(
        `${server_url}/updateProductImage`,
        fmData,
        config
      );
      
      if(res.data && res.data.success) {
        swal("Success", "Product Image has been uploaded successfully!", "success");
      }
    } catch (err) {
      console.log("Error: ", err);
      swal("Error", "Error occured unexpectedly. Please try again!", "error");
      return;
    }
  }

  const handleImageChange1 = async (e) => {
    setPreview1(URL.createObjectURL(e.target.files[0]));
    handleSubmitImage(raffle1, e.target.files[0]);
  }  

  const handleImageChange2 = (e) => {    
    setPreview2(URL.createObjectURL(e.target.files[0]));
    handleSubmitImage(raffle2, e.target.files[0]);
  }

  const handleImageChange3 = (e) => {    
    setPreview3(URL.createObjectURL(e.target.files[0]));
    handleSubmitImage(raffle3, e.target.files[0]);
  }

  const handleImageButtonClick1 = () => {
    if(fileInputRef1.current) {
      fileInputRef1.current.click()
    }
  }

  const handleImageButtonClick2 = () => {
    if(fileInputRef2.current) {
      fileInputRef2.current.click()
    }
  }

  const handleImageButtonClick3 = () => {
    if(fileInputRef3.current) {
      fileInputRef3.current.click()
    }
  }

  const handleRaffleIDChange = (e) => {
    setSelectedRaffle(e.target.value);
  }

  return (
    <div>
      <h2 className='section-header'>Current Raffles</h2>
      <div className='row'>        
        <div className='col-4'>
          <h3 className='timer'>
            Raffle ID: {raffle1}
          </h3>
          <h3 className='timer'>
            {changeTime(timer1)}
          </h3>
          <div className="product-div">
            <img src={preview1?preview1:temp_product_image} />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange1}
              ref={fileInputRef1}
              style={{ display: 'none' }}
            />
          </div>
          <div className="product-div">
            <button className="submit" onClick={handleImageButtonClick1}>
              Upload
            </button>
          </div>
        </div>

        <div className='col-4'>
          <h3 className='timer'>
            Raffle ID: {raffle2}
          </h3>
          <h3 className='timer'>
            {changeTime(timer2)}
          </h3>
          <div className="product-div">
            <img src={preview2?preview2:temp_product_image} />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange2}
              ref={fileInputRef2}
              style={{ display: 'none' }}
            />
          </div>
          <div className="product-div">
            <button className="submit" onClick={handleImageButtonClick2}>
              Upload
            </button>
          </div>
        </div>

        <div className='col-4'>
          <h3 className='timer'>
            Raffle ID: {raffle3}
          </h3>
          <h3 className='timer'>
            {changeTime(timer3)}
          </h3>
          <div className="product-div">
            <img src={preview3?preview3:temp_product_image} />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange3}
              ref={fileInputRef3}
              style={{ display: 'none' }}
            />
          </div>
          <div className="product-div">
            <button className="submit" onClick={handleImageButtonClick3}>
              Upload
            </button>
          </div>
        </div>
      </div>

      <div className='row' style={{marginTop: "50px"}}>        
        <div className='col-5'>
          <div className='card'>
            <h5 className='card-header'>Raffle History</h5>
            <card className='body'>
              <RaffleHistoryTable />
            </card>
          </div>
        </div>

        <div className='col-7'>
          <div className='card'>
            <div style={{ marginBottom: '20px'}}>
              <span className='select-text'>Raffle: </span>
              <select style={{ marginLeft:"30px" }} onChange={handleRaffleIDChange}>
                  <option value=""> All </option>
                  {raffleIDs && raffleIDs.length
                    ? raffleIDs.map((item) => {
                        return (
                            <option key={item} value={item}> {item} </option>
                        );
                    }) : null
                  }
              </select>
            </div>            

            <card className='body'>
              <RafflePaticipantsTable 
                raffle_id={selectedRaffle}
              />
            </card>
          </div>
        </div>
      </div>
    </div>
  )
}
