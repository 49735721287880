import React, { useState } from "react";
import axios from "axios"
import { Link } from 'react-router-dom'
import loginBg from '../../assets/images/thumb.png'
import swal from 'sweetalert';
import './login.css'
const server_url = process.env.REACT_APP_SERVER_URL;

export default function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

  const handleUsernameChange = (e) => {		
		setUsername(e.target.value);
	}

  const handlePasswordChange = (e) => {		
		setPassword(e.target.value);
	}

  const handleSubmit = () => {
    if(username == "" || password == "") {
      swal("Error", "Please input correct credentials.", "error");
      return;
    }

    axios.post(`${server_url}/adminLogin`, {
      name: username,
      password: password
    }).then(res => {
        console.log(res.data);
        if(!res.data.success) {
          if(res.data.flag == "email_not_verified") {
            swal("Error", "This email is not verified yet.", "error");
            return;
          }
          else if(res.data.flag == "no_admin_user") {
            swal("Error", "This user is not admin!", "error");
            return;
          }
          else if(res.data.flag == "failed") {
            swal("Error", "Password is incorrect. Please try again.", "error");
            return;
          }
          else {
            swal("Error", "Error occured unexpectedly. Please try again", "error");
            return;
          }
        }
        else {
          console.log(res);
          localStorage.setItem("token", res.data.data)
          window.location = "/dashboard";
        }
    }).catch(err => {
        console.log(err)
    })
  }

  return (
    <div>
      <div className='row'>
        <div className='col-6'>
          <div className='login-container'>
            <div className='login-group'>
              <h4 className='form-title'>Login</h4>

              <p className='form-description'>
                Only Admin Users can login to the Meta Labs Space Admin Panel.
              </p>
              <div className='form-group'>
                <div className='input-group'>
                  <label htmlFor='username'>Email</label>
                  <input
                    placeholder='example@example.com'
                    type='text'
                    id='username'
                    name='username'
                    onChange={handleUsernameChange}
                  />
                </div>

                <div className='input-group'>
                  <label htmlFor='password'>Password</label>
                  <input
                    placeholder='Place your password here'
                    type='password'
                    id='password'
                    name='password'
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className='submit-container'>
                  <button onClick={handleSubmit} className='submit'>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className='login-container'>
            <img src={loginBg} alt='login' />
          </div>
        </div>
      </div>
    </div>
  )
}
